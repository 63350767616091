import React from 'react'

const Robocop = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 0H0.000204963V21C0.000204963 21 1.95286 32 12.8413 32C18.2547 32 21.7512 30.0225 25.2674 28.0339C27.6499 26.6865 30.0415 25.3339 33.0446 24.5879C35.7142 23.9247 37.9825 21.7782 38.0001 19.0322C38.0177 21.7782 40.286 23.9247 42.9556 24.5879C45.9587 25.3339 48.3503 26.6865 50.7328 28.0339C54.2491 30.0225 57.7455 32 63.1589 32C74.0473 32 76 21 76 21V0H38.0002H38Z"
        fill="#F6E3D0"
      />
      <path
        d="M30 8C30 12.4183 33.5817 16 38 16C42.4183 16 46 12.4183 46 8"
        stroke="#A75548"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </>
  )
}
export default Robocop
