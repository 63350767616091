import React from 'react'

const Smile1 = () => {
  return (
    <>
      <path
        d="M27.0493 8.44151C26.8055 7.36419 27.4811 6.29318 28.5584 6.04935C29.6358 5.80551 30.7068 6.48119 30.9506 7.55851C31.72 10.9578 34.4016 13 37.9999 13C41.5983 13 44.2799 10.9578 45.0493 7.55851C45.2931 6.48119 46.3641 5.80551 47.4414 6.04935C48.5188 6.29318 49.1944 7.36419 48.9506 8.44151C47.7599 13.7024 43.4298 17 37.9999 17C32.5701 17 28.24 13.7024 27.0493 8.44151Z"
        fill="black"
        fillOpacity="0.6"
      />
    </>
  )
}
export default Smile1
