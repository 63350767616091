import React from 'react'

const Hal = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 48C65.2548 48 76 37.2548 76 24C76 10.7452 65.2548 0 52 0C38.7452 0 28 10.7452 28 24C28 37.2548 38.7452 48 52 48Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 44C63.0457 44 72 35.0457 72 24C72 12.9543 63.0457 4 52 4C40.9543 4 32 12.9543 32 24C32 35.0457 40.9543 44 52 44Z"
        fill="black"
        fillOpacity="0.8"
      />
      <path
        d="M64.5726 15.8153C64.8743 16.2779 65.4939 16.4082 65.9565 16.1064C66.419 15.8046 66.5494 15.185 66.2476 14.7225L64.5726 15.8153ZM61.5815 9.95547C61.1256 9.64384 60.5033 9.76084 60.1917 10.2168C59.88 10.6728 59.997 11.295 60.453 11.6067L61.5815 9.95547ZM56.3568 9.64222C56.8854 9.80237 57.4437 9.50373 57.6039 8.97518C57.764 8.44663 57.4654 7.88832 56.9368 7.72816L56.3568 9.64222ZM45.7206 8.19769C45.2074 8.40179 44.9569 8.98326 45.161 9.49645C45.3651 10.0096 45.9465 10.2602 46.4597 10.0561L45.7206 8.19769ZM41.7603 13.0388C42.1638 12.6617 42.1852 12.0289 41.8081 11.6254C41.431 11.2219 40.7981 11.2005 40.3947 11.5776L41.7603 13.0388ZM36.4567 17.1052C36.2325 17.6099 36.4599 18.2008 36.9646 18.425C37.4694 18.6492 38.0603 18.4218 38.2845 17.9171L36.4567 17.1052ZM66.2476 14.7225C65.0212 12.8427 63.433 11.2208 61.5815 9.95547L60.453 11.6067C62.0875 12.7238 63.49 14.1559 64.5726 15.8153L66.2476 14.7225ZM56.9368 7.72816C55.3733 7.25438 53.7155 7 52.0001 7V9C53.5169 9 54.9793 9.2248 56.3568 9.64222L56.9368 7.72816ZM52.0001 7C49.784 7 47.6646 7.42456 45.7206 8.19769L46.4597 10.0561C48.1724 9.37496 50.0413 9 52.0001 9V7ZM40.3947 11.5776C38.7378 13.1261 37.3906 15.0029 36.4567 17.1052L38.2845 17.9171C39.108 16.0633 40.2968 14.4066 41.7603 13.0388L40.3947 11.5776Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 34C57.5228 34 62 29.5228 62 24C62 18.4772 57.5228 14 52 14C46.4772 14 42 18.4772 42 24C42 29.5228 46.4772 34 52 34Z"
        fill="#C6080C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 28C54.2091 28 56 26.2091 56 24C56 21.7909 54.2091 20 52 20C49.7909 20 48 21.7909 48 24C48 26.2091 49.7909 28 52 28Z"
        fill="#EE9337"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 25C52.5523 25 53 24.5523 53 24C53 23.4477 52.5523 23 52 23C51.4477 23 51 23.4477 51 24C51 24.5523 51.4477 25 52 25Z"
        fill="#F5F94F"
      />
    </>
  )
}
export default Hal
