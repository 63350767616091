import React from 'react'

const Square = props => {
  return (
    <>
      <mask
        id="sidemask"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="14"
        y="16"
        width="152"
        height="44"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9809 20.9141C14 22.8393 14 25.3595 14 30.4V45.6C14 50.6405 14 53.1607 14.9809 55.0859C15.8438 56.7794 17.2206 58.1562 18.9141 59.0191C20.8393 60 23.3595 60 28.4 60H35.6C40.6405 60 43.1607 60 45.0859 59.0191C46.7794 58.1562 48.1562 56.7794 49.0191 55.0859C50 53.1607 50 50.6405 50 45.6V30.4C50 25.3595 50 22.8393 49.0191 20.9141C48.1562 19.2206 46.7794 17.8438 45.0859 16.9809C43.1607 16 40.6405 16 35.6 16H28.4C23.3595 16 20.8393 16 18.9141 16.9809C17.2206 17.8438 15.8438 19.2206 14.9809 20.9141ZM130.981 20.9141C130 22.8393 130 25.3595 130 30.4V45.6C130 50.6405 130 53.1607 130.981 55.0859C131.844 56.7794 133.221 58.1562 134.914 59.0191C136.839 60 139.36 60 144.4 60H151.6C156.64 60 159.161 60 161.086 59.0191C162.779 58.1562 164.156 56.7794 165.019 55.0859C166 53.1607 166 50.6405 166 45.6V30.4C166 25.3595 166 22.8393 165.019 20.9141C164.156 19.2206 162.779 17.8438 161.086 16.9809C159.161 16 156.64 16 151.6 16H144.4C139.36 16 136.839 16 134.914 16.9809C133.221 17.8438 131.844 19.2206 130.981 20.9141Z"
          fill="white"
        />
      </mask>
      <g mask="url(#sidemask)">
        <rect width="180" height="76" fill={props.color} />
        <rect y="38" width="180" height="38" fill="black" fillOpacity="0.1" />
      </g>
    </>
  )
}
export default Square
