import React from 'react'

const Round = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 36C27.3137 36 30 33.3137 30 30C30 26.6863 27.3137 24 24 24C20.6863 24 18 26.6863 18 30C18 33.3137 20.6863 36 24 36Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80 36C83.3137 36 86 33.3137 86 30C86 26.6863 83.3137 24 80 24C76.6863 24 74 26.6863 74 30C74 33.3137 76.6863 36 80 36Z"
        fill="white"
      />
    </>
  )
}
export default Round
