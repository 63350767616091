import React from 'react'

const Shade = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 5.58172 3.58172 2 8 2H96C100.418 2 104 5.58172 104 10V28C104 32.4183 100.418 36 96 36H82.9808C79.7112 36.1672 77.4997 37.137 76.0046 38.3473C75.5176 38.7414 75.0239 39.1634 74.5216 39.5927C72.0465 41.7082 69.3652 44 66.2779 44H52H38.6676C35.5324 44 32.732 41.8707 30.0462 39.8285L30.0461 39.8285C29.096 39.1061 28.1602 38.3945 27.229 37.792C25.7725 36.8497 23.7704 36.1407 21.0192 36H8C3.58172 36 0 32.4183 0 28V10Z"
        fill="black"
        fillOpacity="0.8"
      />
      <mask
        id="eyemask"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="104"
        height="42"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10C0 5.58172 3.58172 2 8 2H96C100.418 2 104 5.58172 104 10V28C104 32.4183 100.418 36 96 36H82.9808C79.7112 36.1672 77.4997 37.137 76.0046 38.3473C75.5176 38.7414 75.0239 39.1634 74.5216 39.5927C72.0465 41.7082 69.3652 44 66.2779 44H52H38.6676C35.5324 44 32.732 41.8707 30.0462 39.8285L30.0461 39.8285C29.096 39.1061 28.1602 38.3945 27.229 37.792C25.7725 36.8497 23.7704 36.1407 21.0192 36H8C3.58172 36 0 32.4183 0 28V10Z"
          fill="white"
        />
      </mask>
      <g mask="url(#eyemask)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 19C12 16.2386 14.2386 14 17 14H87C89.7614 14 92 16.2386 92 19V21C92 23.7614 89.7614 26 87 26H74.9808C71.7112 26.1672 69.4997 27.137 68.0046 28.3473C67.5176 28.7414 67.0239 29.1634 66.5216 29.5927C64.5182 31.3051 62.3796 33.133 60 33.7674V34H58.2779H52H46.6676C43.5324 34 40.732 31.8707 38.0462 29.8285L38.0461 29.8285C37.096 29.1061 36.1602 28.3945 35.229 27.792C33.7725 26.8497 31.7704 26.1407 29.0192 26H17C14.2386 26 12 23.7614 12 21V19Z"
          fill="#FF3D3D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 44L32 -2H28L8 44H12ZM50 -2H39L19 44H30L50 -2Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
    </>
  )
}
export default Shade
