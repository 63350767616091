import React from 'react'

const Hearts = () => {
  return (
    <>
      <path
        d="M29.2691 9.67983C26.7216 9.81334 24.305 11.9225 23.0195 13.8332C21.5357 12.0676 18.9175 10.2223 16.3701 10.3558C10.8883 10.6431 7.51531 14.1586 7.74073 18.4598C8.0406 24.1816 12.6244 27.3464 17.4365 30.7046C19.1523 31.8531 22.441 34.8494 22.8627 35.5214C23.2845 36.1935 25.0034 36.1278 25.4425 35.3862C25.8817 34.6446 28.7463 31.3502 30.3355 30.0286C34.7674 26.1859 38.9981 22.5592 38.6982 16.8374C38.4728 12.5362 34.7509 9.39254 29.2691 9.67983Z"
        fill="#FF5353"
        fillOpacity="0.8"
      />
      <path
        d="M87.6299 10.3558C85.0825 10.2223 82.4586 12.0673 80.9805 13.8332C79.6893 11.9222 77.2784 9.81331 74.7309 9.67981C69.2491 9.39252 65.5272 12.5361 65.3017 16.8374C65.0019 22.5591 69.2297 26.1857 73.6645 30.0286C75.2508 31.3501 78.2083 34.6738 78.5575 35.3862C78.9067 36.0987 80.623 36.2131 81.1373 35.5214C81.6515 34.8298 84.8449 31.8529 86.5635 30.7046C91.3728 27.3462 95.9594 24.1816 96.2593 18.4598C96.4847 14.1586 93.1117 10.6431 87.6299 10.3558Z"
        fill="#FF5353"
        fillOpacity="0.8"
      />
    </>
  )
}
export default Hearts
