import React from 'react'

const Antenna2 = props => {
  return (
    <>
      <mask
        id="sidemask"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="10"
        y="9"
        width="160"
        height="51"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160 9H162V28H163.6C165.84 28 166.96 28 167.816 28.436C168.569 28.8195 169.181 29.4314 169.564 30.184C170 31.0397 170 32.1598 170 34.4V53.6C170 55.8402 170 56.9603 169.564 57.816C169.181 58.5686 168.569 59.1805 167.816 59.564C166.96 60 165.84 60 163.6 60H140.4C138.16 60 137.04 60 136.184 59.564C135.431 59.1805 134.819 58.5686 134.436 57.816C134 56.9603 134 55.8402 134 53.6V34.4C134 32.1598 134 31.0397 134.436 30.184C134.819 29.4314 135.431 28.8195 136.184 28.436C137.04 28 138.16 28 140.4 28H160V9ZM10 34.4C10 32.1598 10 31.0397 10.436 30.184C10.8195 29.4314 11.4314 28.8195 12.184 28.436C13.0397 28 14.1598 28 16.4 28H39.6C41.8402 28 42.9603 28 43.816 28.436C44.5686 28.8195 45.1805 29.4314 45.564 30.184C46 31.0397 46 32.1598 46 34.4V53.6C46 55.8402 46 56.9603 45.564 57.816C45.1805 58.5686 44.5686 59.1805 43.816 59.564C42.9603 60 41.8402 60 39.6 60H16.4C14.1598 60 13.0397 60 12.184 59.564C11.4314 59.1805 10.8195 58.5686 10.436 57.816C10 56.9603 10 55.8402 10 53.6V34.4Z"
          fill="white"
        />
      </mask>
      <g mask="url(#sidemask)">
        <rect width="180" height="76" fill={props.color} />
        <rect y="38" width="180" height="38" fill="black" fillOpacity="0.1" />
      </g>
      <rect
        x="160"
        y="8"
        width="2"
        height="20"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161 9C163.209 9 165 7.20914 165 5C165 2.79086 163.209 1 161 1C158.791 1 157 2.79086 157 5C157 7.20914 158.791 9 161 9Z"
        fill="#E1E6E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162 6C163.105 6 164 5.10457 164 4C164 2.89543 163.105 2 162 2C160.895 2 160 2.89543 160 4C160 5.10457 160.895 6 162 6Z"
        fill="white"
      />
    </>
  )
}
export default Antenna2
