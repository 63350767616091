import React from 'react'

const Square1 = () => {
  return (
    <>
      <rect
        x="24"
        y="6"
        width="27"
        height="8"
        rx="4"
        fill="black"
        fillOpacity="0.8"
      />
    </>
  )
}
export default Square1
