import React from 'react'

const Square2 = () => {
  return (
    <>
      <rect
        x="16"
        y="8"
        width="44"
        height="4"
        rx="2"
        fill="black"
        fillOpacity="0.8"
      />
    </>
  )
}
export default Square2
