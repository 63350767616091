import React from 'react'

const Dirty1 = () => {
  return (
    <svg
      width="165"
      height="140"
      viewBox="0 0 165 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.880859 128.092C5.24177 97.4016 13.9384 123.534 34.866 118.124C44.1119 115.734 36.6414 105.296 43.5373 100.772C49.3622 96.9503 57.3002 100.029 63.9726 97.5579C73.6018 93.9913 73.4753 86.7354 82.4474 85.651C90.9322 84.6255 99.8208 88.005 108.378 86.7354C124.529 84.3395 117.907 52.7111 130.018 47.1252C142.129 41.5393 162.068 51.1858 164.678 67C167.222 82.4167 150.22 139.78 150.22 139.78H0.880859"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9268 128C83.6043 128 87.5268 113.25 106.065 106.493C124.603 99.7354 140.846 117.214 147.078 100.233C153.31 83.2528 153.276 136.181 153.276 136.181H45.9268L45.9268 128Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99964 10.8673C19.7328 9.74652 15.9616 22.8061 20.597 22.8061C22.4461 22.8061 24.6249 14.3489 27.9829 14.3489C30.4376 14.3489 29.9749 19.8425 35.3046 19.2617C40.3073 18.7166 39.375 15.2687 41.9023 15.2687C47.345 15.2687 45.2869 35.4636 49.6437 35.4636C54.0005 35.4636 55.3734 20.668 57.2911 14.8164C59.7177 7.4119 74.2632 3.26163 63.8919 0.320129H7.71289"
        fill="black"
        fillOpacity="0.4"
      />
    </svg>
  )
}
export default Dirty1
