import React from 'react'

const Grill3 = () => {
  return (
    <>
      <rect
        x="4"
        y="5"
        width="68"
        height="22"
        rx="5"
        fill="black"
        fillOpacity="0.2"
      />
      <rect x="8" y="9" width="60" height="14" rx="2" fill="white" />
      <rect x="18" y="9" width="4" height="14" fill="black" fillOpacity="0.1" />
      <rect x="42" y="9" width="4" height="14" fill="black" fillOpacity="0.1" />
      <rect x="30" y="9" width="4" height="14" fill="black" fillOpacity="0.1" />
      <rect x="54" y="9" width="4" height="14" fill="black" fillOpacity="0.1" />
    </>
  )
}
export default Grill3
