import React from 'react'

const RoundFrame2 = () => {
  return (
    <>
      <rect
        y="11"
        width="104"
        height="34"
        rx="17"
        fill="black"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 41C36.1797 41 42 35.1797 42 28C42 20.8203 36.1797 15 29 15C21.8203 15 16 20.8203 16 28C16 35.1797 21.8203 41 29 41Z"
        fill="#F1EEDA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75 41C82.1797 41 88 35.1797 88 28C88 20.8203 82.1797 15 75 15C67.8203 15 62 20.8203 62 28C62 35.1797 67.8203 41 75 41Z"
        fill="#F1EEDA"
      />
      <rect
        x="24"
        y="23"
        width="10"
        height="10"
        rx="2"
        fill="black"
        fillOpacity="0.8"
      />
      <rect
        x="70"
        y="23"
        width="10"
        height="10"
        rx="2"
        fill="black"
        fillOpacity="0.8"
      />
    </>
  )
}
export default RoundFrame2
