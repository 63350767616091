import React from 'react'

const Dizzy = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 27.2L30.5 32.7C31 33.1 31.7 33.1 32.1 32.7L33.7 31.1C34.1 30.6 34.1 29.9 33.7 29.5L28.2 24L33.7 18.5C34.1 18 34.1 17.3 33.7 16.9L32.1 15.3C31.6 14.9 30.9 14.9 30.5 15.3L25 20.8L19.5 15.3C19 14.9 18.3 14.9 17.9 15.3L16.3 16.9C15.9 17.3 15.9 18 16.3 18.5L21.8 24L16.3 29.5C15.9 30 15.9 30.7 16.3 31.1L17.9 32.7C18.4 33.1 19.1 33.1 19.5 32.7L25 27.2Z"
        fill="black"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79 27.2L84.5 32.7C85 33.1 85.7 33.1 86.1 32.7L87.7 31.1C88.1 30.6 88.1 29.9 87.7 29.5L82.2 24L87.7 18.5C88.1 18 88.1 17.3 87.7 16.9L86.1 15.3C85.6 14.9 84.9 14.9 84.5 15.3L79 20.8L73.5 15.3C73 14.9 72.3 14.9 71.9 15.3L70.3 16.9C69.9 17.3 69.9 18 70.3 18.5L75.8 24L70.3 29.5C69.9 30 69.9 30.7 70.3 31.1L71.9 32.7C72.4 33.1 73.1 33.1 73.5 32.7L79 27.2Z"
        fill="black"
        fillOpacity="0.8"
      />
    </>
  )
}
export default Dizzy
